import { useCallback, useSyncExternalStore } from 'react';
import store from 'store';

const subscribe = (callback: () => void): (() => void) => {
  window.addEventListener('storage', callback);
  return () => {
    window.removeEventListener('storage', callback);
  };
};

export const useStoredValue = (key: string, defaultValue?: string): [string, (value: string) => void] => {
  const value = useSyncExternalStore(
    subscribe,
    useCallback(() => store.get(key, defaultValue), [defaultValue, key]),
  );

  const setValue = (value: string) => {
    store.set(key, value);
    window.dispatchEvent(new Event('storage'));
  };

  return [value, setValue];
};
