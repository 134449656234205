import generalMessages from './translations/en.json';
import carrierNames from './translations/carriers.json';

const combinedMessages = {
  ...carrierNames,
  ...generalMessages,
};

export const english = {
  locale: 'en-GB',
  messages: combinedMessages,
};
