import { useStoredValue } from './useStoredValue';

export function useTheme() {
  const [theme, setTheme] = useStoredValue('app.settings.theme', 'light');

  if (theme !== 'light' && theme !== 'dark') {
    return ['light', setTheme] as const;
  }

  return [theme, setTheme] as const;
}
