import { Link, Size, twMerge } from '@pledge-earth/product-language';
import { FormattedMessage } from 'react-intl';

import { CookieYesLink } from '../CookieYesLink/CookieYesLink';
import { ExternalLink } from '../Link/ExternalLink';

interface FooterProps {
  size?: Size.Default | Size.Compact;
  variant?: 'monochrome' | 'contrast-brand';
  className?: string;
}

export function Footer({ size = Size.Default, variant = 'monochrome', className }: FooterProps) {
  return (
    <div
      className={twMerge(
        'flex items-center justify-center gap-4 text-center',
        variant === 'contrast-brand' ? 'text-contrast-brand' : 'text-default',
        size === Size.Default ? 'text-body-md' : 'text-body-sm',
        className,
      )}
    >
      <ExternalLink size={size} variant={variant} href="https://www.pledge.io/privacy" noExternalIcon={true}>
        <FormattedMessage id="privacy_policy" />
      </ExternalLink>
      <span>&bull;</span>
      <ExternalLink size={size} variant={variant} href="https://www.pledge.io/terms" noExternalIcon={true}>
        <FormattedMessage id="terms" />
      </ExternalLink>
      <span>&bull;</span>
      <CookieYesLink>
        {(props) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link {...props} href="#" size={size} variant={variant}>
            <FormattedMessage id="cookie-settings" />
          </Link>
        )}
      </CookieYesLink>
    </div>
  );
}
